<script setup lang="ts">
import { BAR_GAP, BAR_WIDTH, PLOT_MARGIN_FREE, PLOT_MARGIN_X_AXIS, PLOT_MARGIN_Y_AXIS_LEFT, PLOT_MARGIN_Y_AXIS_RIGHT, PLOT_MINIMUM_HEIGHT } from './chart-sizes'
import { computed, ref } from 'vue'
import { type PlotBand, type PlotLine, type ZoomParameters } from './types'
import { TIMESERIES_COLORS, VUETIFY_COLORS } from '@theme/colors'
import { Chart } from 'highcharts-vue'
import { CustomSeriesData } from './TimeseriesViewer.types'
import { formatValue } from '@/filters/formatting'
import Highcharts from 'highcharts'
import { merge } from 'lodash'
import moment from 'moment'
import nodata from 'highcharts/modules/no-data-to-display'
import { TYPOGRAPHY } from '@/utils/designConstants'
import { useI18n } from 'vue-i18n'
import xrange from 'highcharts/modules/xrange'

interface Props {
  customOptions?: Highcharts.ChartOptions,
  plotBands: PlotBand[],
  plotLines: PlotLine[],
  series: Highcharts.SeriesXrangeOptions[],
  shouldHaveMargin: boolean,
  shouldHaveSecondYAxis: boolean,
  shouldHaveTicks: boolean,
  xMax: Date,
  xMin: Date
}

// --- definition ---

const props = withDefaults(defineProps<Props>(), {
  customOptions: () => ({}),
})

const emit = defineEmits<{
  (e: 'x-range-chart:reset-zoom'): void;
  (e: 'x-range-chart:zoom', params: ZoomParameters): void;
  (e: 'x-range-chart:reset-cursor'): void;
  (e: 'x-range-chart:set-cursor', params: { x1: number, x2: number }): void;
}>()

const { t } = useI18n()

const chart = ref<typeof Chart|null>(null)

const chartHeight = computed(() => {
  return props.series.length * (BAR_WIDTH + BAR_GAP + 2)
})

// if there’s only few datapoints displayed, there’s not enough room to display
// their tooltips and the y axis’ title, so we provide a little more margin on
// both sides in this case
const chartExtraSpaceForTooltip = computed(() => {
  if (PLOT_MINIMUM_HEIGHT < chartHeight.value) {
    return 0
  }
  return PLOT_MINIMUM_HEIGHT - chartHeight.value
})

const chartXAxisMargin = computed(() => {
  if (props.shouldHaveMargin) {
    if (props.shouldHaveTicks) {
      return PLOT_MARGIN_X_AXIS
    }
    return PLOT_MARGIN_FREE
  }
  return 0
})

// execution

nodata(Highcharts)
xrange(Highcharts)

/**
 * https://github.com/highcharts/highcharts-vue/issues/71#issuecomment-485784315
 * The 'lang' object can not be updated to force a re-draw. Languages only change on rendering (create/destroy hooks)
 */
Highcharts.setOptions({
  accessibility: {
    enabled: false,
  },
  chart: {
    style: {
      fontFamily: "'Inter', sans-serif",
    },
  },
  lang: {
    loading: t('highcharts.loading'),
    noData: t('highcharts.no_data'),
    shortWeekdays: [
      t('dates.short_weekdays.sunday'),
      t('dates.short_weekdays.monday'),
      t('dates.short_weekdays.tuesday'),
      t('dates.short_weekdays.wednesday'),
      t('dates.short_weekdays.thursday'),
      t('dates.short_weekdays.friday'),
      t('dates.short_weekdays.saturday'),
    ],
  },
  noData: {
    style: {
      color: VUETIFY_COLORS.neutral.darken2,
      fontSize: TYPOGRAPHY.headings.body1.size,
      fontWeight: TYPOGRAPHY.headings.body1.weight,
    },
  },
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
})

const chartOptions = computed(() => {
  const result: Highcharts.Options = {
    chart: {
      events: {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        selection: (event: any) => {
          if (event.resetSelection) {
            emit('x-range-chart:reset-zoom')
          } else {
            const newStartTime: number = event.xAxis[0].min
            const newEndTime: number = event.xAxis[0].max
            emit('x-range-chart:zoom', {
              end: newEndTime,
              start: newStartTime,
            } as ZoomParameters)
          }
          return false
        },
      },
      height: chartHeight.value + chartXAxisMargin.value + chartExtraSpaceForTooltip.value,
      margin: [
        chartExtraSpaceForTooltip.value / 2,
        props.shouldHaveSecondYAxis ? PLOT_MARGIN_Y_AXIS_RIGHT : PLOT_MARGIN_FREE,
        chartXAxisMargin.value + (chartExtraSpaceForTooltip.value / 2),
        PLOT_MARGIN_Y_AXIS_LEFT,
      ],
      type: 'xrange',
      zooming: {
        type: 'x',
      },
    },
    colors: TIMESERIES_COLORS,
    credits: {
      enabled: false,
    },
    lang: {
      noData: t('highcharts.binary_no_data'),
    },
    legend: {
      enabled: false,
    },
    loading: {
      // @ts-ignore
      useHTML: true,
    },
    plotOptions: {
      series: {
        animation: false,
        // @ts-ignore
        borderRadius: 0,
        grouping: false,
        point: {
          events: {
            mouseOut: () => {
              emit('x-range-chart:reset-cursor')
            },
            mouseOver: (mouseOverEvent: Event) => {
              emit('x-range-chart:set-cursor', {
                x1: (mouseOverEvent.target as unknown as Highcharts.Point).x,
                x2: (mouseOverEvent.target as unknown as Highcharts.Point).x2 as number,
              })
            },
          },
        },
        pointWidth: BAR_WIDTH + 2,
        turboThreshold: 0,
      },
    },
    title: {
      text: undefined,
    },
    tooltip: {
      backgroundColor: `${VUETIFY_COLORS.neutral.lighten5}ce`,
      borderColor: VUETIFY_COLORS.neutral.lighten1,
      borderRadius: 4,
      borderWidth: 1,
      formatter () {
        const ctx = this as Highcharts.TooltipFormatterContextObject & {
          point: { custom: { values: number[] } };
          series: {
            userOptions: { custom: CustomSeriesData; unit: string };
          };
          x2: number;
        }

        const pointCustom = ctx.point.custom as { values: number[] }
        const seriesCustom = ctx.series.userOptions.custom as CustomSeriesData
        const offset = moment(ctx.x).utcOffset() / 60
        const offsetString = offset > 0 ? `+${offset}` : `${offset}`
        const date1 = moment(ctx.x).format('DD.MM.YYYY, HH:mm:ss')
        const date2 = moment(ctx.x2).format('DD.MM.YYYY, HH:mm:ss')
        const values = pointCustom.values
          .map((value) => {
            return formatValue(value, {
              fallbackValue: '',
              subAndSuperUnit: true,
              unit: seriesCustom.unit,
            })
          })
          .join('</b>, <b>')

        return `${date1} - ${date2} <b>UTC${offsetString}</b><br>${this.series.name}: <b>${values}</b>`
      },
      shadow: false,
      style: {
        color: VUETIFY_COLORS.neutral.darken3,
      },
      useHTML: true,
      valueDecimals: 2,
    },
    xAxis: {
      dateTimeLabelFormats: {
        day: t('dates.chart.weekday_date_format'),
      },
      gridLineColor: VUETIFY_COLORS.neutral.lighten2,
      gridLineDashStyle: 'ShortDash',
      gridLineWidth: 1,
      labels: {
        enabled: props.shouldHaveTicks,
        style: {
          color: VUETIFY_COLORS.neutral.darken3,
          fontSize: TYPOGRAPHY.headings.legend.size,
          fontWeight: TYPOGRAPHY.headings.legend.weight,
        },
      },
      lineColor: props.shouldHaveTicks ? VUETIFY_COLORS.neutral.darken3 : 'transparent',
      max: props.xMax.valueOf(),
      min: props.xMin.valueOf(),
      plotBands: props.plotBands,
      plotLines: props.plotLines,
      tickLength: props.shouldHaveTicks ? 10 : 0,
      type: 'datetime',
    },
    yAxis: {
      categories: new Array(props.series.length).fill(''),
      gridLineWidth: 0,
      title: {
        text: t('highcharts.binary_values'),
      },
    },
  }
  merge(result, props.customOptions)
  // This is done after creating the object to ensure that the series comes from the prop, not from customOptions
  Object.assign(result, {
    series: props.series,
  })
  return result
})
</script>

<template>
  <Chart
    ref="chart"
    :options="chartOptions"
    :update-args="[true, true, true]"
  />
</template>
